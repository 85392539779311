import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from './css/AdminDashboard.module.css';

const AdminDashboard = ({ token, setClient }) => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [clientStats, setClientStats] = useState([]);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsers(response.data);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch users');
      }
    };

    const fetchClientStats = async () => {
      try {
        const { startDate, endDate } = filters;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/client-stats`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { start_date: startDate, end_date: endDate }
        });
        setClientStats(response.data);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch client stats');
      }
    };

    fetchUsers();
    fetchClientStats();
  }, [token, filters]);

  const handlePseudoLogin = async (username) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/client-settings`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { username }
      });
      if (response.status === 200) {
        navigate(`/qpetext?pseudoUser=${username}`);
      } else {
        console.error('Failed to fetch client settings');
      }
    } catch (err) {
      console.error('Failed to fetch client settings', err);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/login');
    } catch (err) {
      console.error('Failed to logout', err);
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.dashboardHeader}>
        <div className={styles.logoContainer}>
          <img src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg" alt="Logo" className={styles.dashboardLogo} />
          <span className={styles.logoText}>Admin Dashboard</span>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.welcomeContainer}>
            <span className={styles.welcomeText}>Welcome, Admin</span>
          </div>
          <button onClick={handleLogout} className={styles.logoutBtn}>Logout</button>
        </div>
      </div>
      {message && <p>{message}</p>}
      <div className={styles.dashboardContent}>
        <div className={`${styles.configurationContainer} ${styles.contentBox}`}>
          <h2>User Configuration</h2>
          <div className={styles.userTableContainer}>
            <table className={styles.userTable}>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Client Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.username}>
                    <td>{user.username}</td>
                    <td>{user.client_status}</td>
                    <td>
                      <button 
                        onClick={() => navigate(`/config?username=${user.username}`)} 
                        className={styles.configBtn}>
                        Configure
                      </button>
                      <button 
                        onClick={() => handlePseudoLogin(user.username)} 
                        className={styles.pseudoBtn}>
                        Pseudo QFE
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={`${styles.insightSuite} ${styles.contentBox}`}>
          <h2>Client Statistics</h2>
          <div className={styles.filterContainer}>
            <label>
              Start Date:
              <input type="date" name="startDate" value={filters.startDate} onChange={handleFilterChange} />
            </label>
            <label>
              End Date:
              <input type="date" name="endDate" value={filters.endDate} onChange={handleFilterChange} />
            </label>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={clientStats}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="GeneratedItems" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className={`${styles.adminTools} ${styles.contentBox}`}>
          <h2>Admin Tools</h2>
          <button 
            onClick={() => navigate('/qptext')} 
            className={`${styles.adminToolsBtn} ${styles.dashboardBtn}`}>
            Text Generation Page
          </button>
          <button 
            onClick={() => navigate('/image-generation')}  
            className={`${styles.adminToolsBtn} ${styles.dashboardBtn}`}>
            Image Generation Page
          </button>
          <button 
            onClick={() => navigate('/custom-attributes')} 
            className={`${styles.adminToolsBtn} ${styles.dashboardBtn}`}>
            Custom Attribute Page
          </button>
          <button 
            onClick={() => navigate('/register')} 
            className={`${styles.adminToolsBtn} ${styles.dashboardBtn}`}>
            Register New User
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

