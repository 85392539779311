// src/components/ImageGeneration.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSyncAlt, FaColumns, FaPlus, FaPlay, FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa';
import { AiOutlineCloud } from 'react-icons/ai';
import Select from 'react-select';
import styles from './css/ImageGeneration.module.css';
import { useTable } from 'react-table';
import ClipLoader from "react-spinners/ClipLoader";
import PopupUploaded from '../popups/PopupUploaded';
import PopupBackground from '../popups/PopupBackground';
import PopupUncropped from '../popups/PopupUncropped';
import PopupImport from '../popups/PopupImport';
import PopupTransform from '../popups/PopupTransform';
import PopupExport from '../popups/PopupExport';
import PopupBulkActions from '../popups/PopupBulkActions';

const FALLBACK_IMAGE_URL = 'https://storage.googleapis.com/quantum-feed-engine/workbench/error_image.png';

const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

const ImageGeneration = ({ isAdmin }) => {
  const navigate = useNavigate();
  const [linkedClients, setLinkedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientData, setClientData] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filterColumn, setFilterColumn] = useState('');
  const [filterType, setFilterType] = useState('equals');
  const [filterValue, setFilterValue] = useState('');
  const [filterLogic, setFilterLogic] = useState('AND');
  const [filters, setFilters] = useState([{ column: '', type: 'equals', value: '', logic: 'AND' }]);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const maxPage = Math.ceil(totalItems / itemsPerPage);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mode, setMode] = useState('generate'); // 'generate', 'selector', 'transform'
  const [isNoBackgroundPopupOpen, setIsNoBackgroundPopupOpen] = useState(false);
  const [noBackgroundImage, setNoBackgroundImage] = useState(null);
  const [isPopupUncroppedOpen, setIsPopupUncroppedOpen] = useState(false);
  const [selectedUncroppedImage, setSelectedUncroppedImage] = useState(null);
  const [isPopupImportOpen, setIsPopupImportOpen] = useState(false);
  const [selectedImportImage, setSelectedImportImage] = useState(null);
  const [isPopupTransformOpen, setIsPopupTransformOpen] = useState(false);
  const [isPopupExportOpen, setIsPopupExportOpen] = useState(false);
  const [isPopupBulkActionsOpen, setIsPopupBulkActionsOpen] = useState(false);
  const [transformImage, setTransformImage] = useState(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const [storageInfo, setStorageInfo] = useState({ file_count: 0, total_size_mb: 0 });
  const [storageLoading, setStorageLoading] = useState(false);

  const [selectedImages, setSelectedImages] = useState({});

  const [loadingImages, setLoadingImages] = useState({});

  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null); // State for last selected row index

  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: '#292B3D',
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      zIndex: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'DM Sans, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'DM Sans, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 })
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(true);
      }
    };
  
    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    applyFilters(1);
  }, [itemsPerPage]);

  useEffect(() => {
    const fetchLinkedClients = async () => {
      try {
        const response = await fetch(`${API_URL_IMAGE}/client-data`, { // Updated URL
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const clientOptions = data.linked_clients.map(client => ({ value: client, label: client }));
          setLinkedClients(clientOptions);
        } else {
          console.error('Failed to fetch linked clients');
        }
      } catch (error) {
        console.error('Error fetching linked clients:', error);
      }
    };

    fetchLinkedClients();
  }, []);

  const handleClientChange = async (selectedOption) => {
    setSelectedClient(selectedOption.value);
    setCurrentPage(1);
  
    // Immediately set storageLoading to true to show the spinner
    setStorageLoading(true);
  
    try {
      const response = await fetch(`${API_URL_IMAGE}/fetch-client-data`, { // Updated URL
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_name: selectedOption.value,
          filters: [],
          sort_column: sortColumn,
          sort_order: sortOrder,
          limit: itemsPerPage,
          offset: 0,
        }),
      });
  
      if (response.ok) {
        const { data, total_items } = await response.json();
        setClientData(data);
        setTotalItems(total_items);
  
        if (data.length > 0) {
          const desiredOrder = [
            'item_group_id',
            'id',
            'gtin',
            'title',
            'brand',
            'product_type',
            'google_product_category',
            'pattern',
            'material',
            'color',
            'gender',
            'age_group',
            'size',
            'link',
            'image_link_data',
            ...Array.from({ length: 10 }, (_, i) => `additional_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`),
            ...Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`),
            ...Array.from({ length: 10 }, (_, i) => `custom_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`)
          ];

          const filteredColumns = Object.keys(data[0])
            .filter(key => !key.endsWith('_uncropped') && !key.endsWith('_background') && !key.endsWith('_upload'))
            .map(key => ({ value: key, label: key.replace(/_/g, ' ').toUpperCase() }))
            .sort((a, b) => {
              const indexA = desiredOrder.indexOf(a.value);
              const indexB = desiredOrder.indexOf(b.value);
              return indexA - indexB;
            });

          setAvailableColumns(filteredColumns);
          setSelectedColumns([]);
        }

        // Fetch storage info
        await fetchStorageInfo(selectedOption.value);

      } else {
        console.error('Failed to fetch client data');
        setStorageLoading(false); // Stop loading if there's an error
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
      setStorageLoading(false); // Stop loading if there's an error
    }
  };
  

  const handleNextPage = async () => {
    if (currentPage * itemsPerPage < totalItems) {
      setIsPageLoading(true);
      setLoadingButton('next');
      await applyFilters(currentPage + 1);
      setIsPageLoading(false);
      setLoadingButton(null);
    }
  };

  const fetchStorageInfo = async (clientName) => {
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/client-storage?client_name=${encodeURIComponent(clientName)}`, // Updated URL
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        setStorageInfo({
          file_count: data.file_count,
          total_size_mb: data.total_size_mb,
        });
      } else {
        console.error('Failed to fetch client storage info');
        setStorageInfo({
          file_count: 0,
          total_size_mb: 0,
        });
      }
    } catch (error) {
      console.error('Error fetching client storage info:', error);
      setStorageInfo({
        file_count: 0,
        total_size_mb: 0,
      });
    } finally {
      setStorageLoading(false); // End loading
    }
  };
  

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setIsPageLoading(true);
      setLoadingButton('prev');
      await applyFilters(currentPage - 1);
      setIsPageLoading(false);
      setLoadingButton(null);
    }
  };

  const openPopup = (imageUrl, id, columnName) => {
    const effectiveImageUrl = imageUrl || FALLBACK_IMAGE_URL;
    console.log("Opening popup with image:", effectiveImageUrl, "ID:", id, "from column:", columnName, "for client:", selectedClient);
    setSelectedImage({ url: effectiveImageUrl, id, column: columnName, client: selectedClient });
    setIsPopupOpen(true);
  };

  const openPopupBackground = (imageUrl, id, columnName) => {
    const effectiveImageUrl = imageUrl || FALLBACK_IMAGE_URL;
    if (!imageUrl) {
      console.error('No image URL available for No Background image. Using fallback.');
    } else {
      console.log("Opening No BG popup with image:", effectiveImageUrl, "ID:", id, "from column:", columnName, "for client:", selectedClient);
    }
    setNoBackgroundImage({ url: effectiveImageUrl, id, column: columnName, client: selectedClient });
    setIsNoBackgroundPopupOpen(true);
  };

  const openPopupUncropped = (imageUrl, itemId, columnName) => {
    const effectiveImageUrl = imageUrl || FALLBACK_IMAGE_URL;
    console.log("Opening PopupUncropped with image:", effectiveImageUrl, "ID:", itemId, "from column:", columnName, "for client:", selectedClient);
    setSelectedUncroppedImage({ url: effectiveImageUrl, id: itemId, column: columnName, client: selectedClient });
    setIsPopupUncroppedOpen(true);
  };

  const closePopupUncropped = () => {
    setIsPopupUncroppedOpen(false);
    setSelectedUncroppedImage(null);
  };

  const closeNoBackgroundPopup = () => {
    setIsNoBackgroundPopupOpen(false);
    setNoBackgroundImage(null);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedImage(null);
  };

  const openPopupImport = (imageUrl, itemId, columnName) => {
    const effectiveImageUrl = imageUrl || FALLBACK_IMAGE_URL;
    console.log(
      "Opening PopupImport with image:",
      effectiveImageUrl,
      "ID:",
      itemId,
      "from column:",
      columnName,
      "for client:",
      selectedClient
    );
    setSelectedImportImage({
      url: effectiveImageUrl,
      id: itemId,
      columnName: columnName,
      client: selectedClient,
    });
    setIsPopupImportOpen(true);
  };

  const openPopupTransform = (imageUrl, id, columnName, imageKey) => {
    const effectiveImageUrl = imageUrl || FALLBACK_IMAGE_URL;
    setTransformImage({
      url: effectiveImageUrl,
      id,
      column: columnName,
      client: selectedClient,
      imageKey, // Include the imageKey
    });
    setIsPopupTransformOpen(true);
  };

  // Function to close PopupTransform
  const closePopupTransform = () => {
    setIsPopupTransformOpen(false);
    setTransformImage(null);
  };

  const openPopupExport = () => {
    setIsPopupExportOpen(true);
  };

  const closePopupExport = () => {
    setIsPopupExportOpen(false);
  };

  const toggleImageSelection = async (itemId, columnName, imageKey, newSelectionState) => {
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/update-selection-state`, { // Updated URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          client_name: selectedClient,
          id: itemId,
          image_key: imageKey,
          selected: newSelectionState,
        }),
      });

      if (response.ok) {
        setClientData(prevData => {
          return prevData.map(item => {
            if (item.id === itemId) {
              const updatedImageData = { ...item[columnName] };
              updatedImageData[`${imageKey}_selected`] = newSelectionState;
              return { ...item, [columnName]: updatedImageData };
            }
            return item;
          });
        });
      } else {
        console.error('Failed to update selection state');
      }
    } catch (error) {
      console.error('Error updating selection state:', error);
    }
  };

  const closePopupImport = () => {
    setIsPopupImportOpen(false);
    setSelectedImportImage(null);
  };

  const addFilter = () => {
    setFilters([...filters, { column: '', type: 'equals', value: '', logic: 'AND' }]);
  };

  const handleFilterLogicChange = (e) => {
    setFilterLogic(e.target.value);
  };

  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const areAllColumnsSelected = () => {
    return filters.every(filter => filter.column);
  };

  const handleFilterChange = (index, field, value) => {
    const updatedFilters = [...filters];
  
    updatedFilters[index][field] = value;
  
    if (field === 'type') {
      if (value === 'is_blank' || value === 'is_not_blank') {
        updatedFilters[index].value = '';
      }
    }
  
    setFilters(updatedFilters);
  };
  

  const handleSortColumnChange = (selectedOption) => {
    setSortColumn(selectedOption.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const applyFilters = async (page = currentPage) => {
    const filterParams = filters.map(filter => {
      if (filter.type === 'in' || filter.type === 'not_in') {
        const values = filter.value.split(/,|\s+/).map(val => val.trim()).filter(val => val);
        return {
          column: filter.column,
          type: filter.type,
          value: values,
          logic: filter.logic
        };
      }
      return {
        column: filter.column,
        type: filter.type,
        value: filter.value,
        logic: filter.logic
      };
    });
  
    try {
      const response = await fetch(`${API_URL_IMAGE}/fetch-client-data`, { // Updated URL
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_name: selectedClient,
          filters: filterParams,
          sort_column: sortColumn,
          sort_order: sortOrder,
          limit: itemsPerPage,
          current_page: page
        })
      });
  
      if (response.ok) {
        const { data, total_items, current_page, total_pages } = await response.json();
        setClientData(data);
        setTotalItems(total_items);
        setCurrentPage(current_page);
      } else {
        console.error('Failed to fetch client data');
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };
  

  const jumpToPage = async () => {
    const maxPage = Math.ceil(totalItems / itemsPerPage);
    let page = parseInt(currentPage, 10);

    if (isNaN(page) || page < 1) {
      page = 1;
    } else if (page > maxPage) {
      page = maxPage;
    }

    setIsPageLoading(true);
    setLoadingButton('jump');

    await applyFilters(page);

    setIsPageLoading(false);
    setLoadingButton(null);
  };

  const handleRefresh = async () => {
    if (!selectedClient) {
      console.error('No client selected');
      return;
    }
  
    try {
      const response = await fetch(`${API_URL_IMAGE}/refresh-feed-data?client_name=${encodeURIComponent(selectedClient)}`, { // Updated URL
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.ok) {
        console.log('Feed data refreshed successfully');
        handleClientChange({ value: selectedClient });
      } else {
        console.error('Failed to refresh feed data');
      }
    } catch (error) {
      console.error('Error refreshing feed data:', error);
    }
  };

  
  const refreshData = () => {
    applyFilters(currentPage);
  };

  const handleColumnSelection = (selectedOptions) => {
    setSelectedColumns(selectedOptions || []);
  };

  const hiddenFilterColumns = [
    'image_link_data',
    ...Array.from({ length: 10 }, (_, i) => `additional_image_link_${i + 1 < 10 ? `0${i + 1}` : i + 1}_data`),
    ...Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${i + 1 < 10 ? `0${i + 1}` : i + 1}_data`),
    ...Array.from({ length: 10 }, (_, i) => `custom_image_link_${i + 1 < 10 ? `0${i + 1}` : i + 1}_data`),
  ];

  const filterAvailableColumns = availableColumns.filter(col => !hiddenFilterColumns.includes(col.value));

  const handleStandardView = () => {
    const standardColumns = [
      'id',
      'image_link_data',
      ...Array.from({ length: 10 }, (_, i) => `additional_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`),
      ...Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`),
      ...Array.from({ length: 10 }, (_, i) => `custom_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}_data`),
    ];

    const selected = availableColumns.filter(col => standardColumns.includes(col.value));
    setSelectedColumns(selected);

    setSelectedColumns(prevSelected => {
      const updatedSelected = [...prevSelected, ...selected];
      return [...new Set(updatedSelected)];
    });
  };

  // Function to get image type color
  const getImageTypeColor = (imageType) => {
    switch (imageType.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
        return '#6B8D94';
      case 'png':
        return '#AA6355';
      case 'webp':
        return '#836B94';
      default:
        return '#292B3D';
    }
  };

  const handleRowSelect = (row, event) => {
    const rowId = row.original.id;
  
    if (event.shiftKey && lastSelectedRowIndex !== null) {
      const start = Math.min(lastSelectedRowIndex, row.index);
      const end = Math.max(lastSelectedRowIndex, row.index);
  
      const rowIdsInRange = rows.slice(start, end + 1).map(r => r.original.id);
  
      const isRowSelected = selectedRows.includes(rowId);
  
      setSelectedRows(prevSelected => {
        let newSelected;
  
        if (isRowSelected) {
          // Deselect the range
          newSelected = prevSelected.filter(id => !rowIdsInRange.includes(id));
        } else {
          // Select the range
          newSelected = [...new Set([...prevSelected, ...rowIdsInRange])];
        }
  
        return newSelected;
      });
    } else {
      // Toggle the selection of the current row
      setSelectedRows(prevSelected => {
        if (prevSelected.includes(rowId)) {
          return prevSelected.filter(id => id !== rowId);
        } else {
          return [...prevSelected, rowId];
        }
      });
    }
  
    // Update lastSelectedRowIndex
    setLastSelectedRowIndex(row.index);
  };
  

  const handleSelectAllRows = () => {
    const pageRowIds = clientData.map(item => item.id);
    if (pageRowIds.every(id => selectedRows.includes(id))) {
      // Deselect all on current page
      setSelectedRows(prevSelected => prevSelected.filter(id => !pageRowIds.includes(id)));
    } else {
      // Select all on current page
      setSelectedRows(prevSelected => [...new Set([...prevSelected, ...pageRowIds])]);
    }

    // Reset lastSelectedRowIndex
    setLastSelectedRowIndex(null);
  };

  useEffect(() => {
    // Reset lastSelectedRowIndex when data changes (e.g., on pagination)
    setLastSelectedRowIndex(null);
  }, [clientData]);

  const columns = React.useMemo(() => {
    if (clientData.length === 0) return [];

    // Define the select column
    const pageRowIds = clientData.map(item => item.id);
    const allPageRowsSelected = pageRowIds.every(id => selectedRows.includes(id));

    const selectColumn = {
      id: 'selection',
      Header: (
        <input
          type="checkbox"
          checked={allPageRowsSelected}
          onChange={handleSelectAllRows}
        />
      ),
      Cell: ({ row }) => {
        const isSelected = selectedRows.includes(row.original.id);
    
        const handleCheckboxChange = (event) => {
          event.stopPropagation(); // Prevent the cell's onClick from firing
          handleRowSelect(row, event);
        };
    
        return (
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            className={styles.checkbox} // Optional: Add a class for styling
          />
        );
      },
    };
    

    const otherColumns = selectedColumns.length > 0 ? selectedColumns.map(col => ({
      Header: col.label,
      accessor: col.value,
      Cell: ({ row, cell: { value }, column }) => {
        const itemId = row.original.id;

        if (typeof value === 'object' && value !== null) {
          const imagePriority = ['_primary', '_uncropped', '_background', '_upload', '_import'];
          const sortedImages = Object.entries(value)
            .filter(([key]) => !key.endsWith('_selected'))
            .sort(([keyA], [keyB]) => {
              const indexA = imagePriority.findIndex(priority => keyA.endsWith(priority));
              const indexB = imagePriority.findIndex(priority => keyB.endsWith(priority));
              return (indexA !== -1 ? indexA : imagePriority.length) - (indexB !== -1 ? indexB : imagePriority.length);
            });

          let originalImageUrl = null;

          const images = sortedImages.map(([key, url]) => {
            let label = '';
            let showOverlay = false;
          
            if (key.endsWith('_primary')) {
              label = 'Original';
              originalImageUrl = url;
              if (mode !== 'generate') {
                showOverlay = true;
              }
            }
            if (key.endsWith('_uncropped')) {
              label = 'Uncrop';
              showOverlay = true;
            }
            if (key.endsWith('_background')) {
              label = 'Background';
              showOverlay = true;
            }
            if (key.endsWith('_upload')) {
              label = 'Upload';
              showOverlay = true;
            }
            if (key.endsWith('_import')) {
              label = 'Import';
              showOverlay = true;
            }

            // New code to prevent overlay on fallback images in Generate Mode
            // Prevent overlay on fallback images in all modes
            if ((mode === 'selector' || mode === 'transform') && (url === FALLBACK_IMAGE_URL || !url)) {
              showOverlay = false;
            }


            // New code to handle the fallback image in Transform Mode
            const isFallbackImage = url === FALLBACK_IMAGE_URL;
          
            if (mode === 'transform' && isFallbackImage) {
              showOverlay = false;
            }

            const selectedKey = `${key}_selected`;
            const isSelected = value[selectedKey];

            const imageIdKey = `${itemId}_${key}`;
            const isLoading = loadingImages[imageIdKey];

            // Determine image type label
            let imageTypeLabel = '';
            if (url && url !== FALLBACK_IMAGE_URL && mode === 'transform') {
              try {
                const urlObj = new URL(url);
                const pathname = urlObj.pathname;
                const extensionMatch = pathname.match(/\.(\w+)(\?|$)/);
                if (extensionMatch && extensionMatch[1]) {
                  imageTypeLabel = extensionMatch[1].toUpperCase();
                } else {
                  imageTypeLabel = 'OTHER';
                }
              } catch (error) {
                console.error('Invalid URL:', url);
                imageTypeLabel = 'OTHER';
              }
            }

            return (
              <div
                className={`${styles.imageContainer} ${
                  mode === 'selector' && isSelected ? styles.selectedImageContainer : ''
                }`}
                key={key}
              >
                {isLoading ? (
                  <div className={styles.spinnerContainer}>
                    <ClipLoader size={50} color={"#5CC0D6"} />
                  </div>
                ) : (
                  <img
                    src={url || FALLBACK_IMAGE_URL}
                    alt={key}
                    className={`${styles.imageStyle} ${isSelected ? styles.selectedImage : ''}`}
                  />
                )}
                {!isLoading && label && (
                  <span className={styles.imageText}>
                    {label}
                  </span>
                )}
                {!isLoading && showOverlay && (
                  <div
                    className={styles.imageOverlay}
                    onClick={() => {
                      if (mode === 'generate') {
                        const effectiveImageUrl = originalImageUrl || FALLBACK_IMAGE_URL;
                        if (key.endsWith('_upload')) {
                          openPopup(effectiveImageUrl, itemId, column.id);
                        } else if (key.endsWith('_background')) {
                          openPopupBackground(effectiveImageUrl, itemId, column.id);
                        } else if (key.endsWith('_uncropped')) {
                          openPopupUncropped(effectiveImageUrl, itemId, column.id);
                        } else if (key.endsWith('_import')) {
                          openPopupImport(effectiveImageUrl, itemId, column.id);
                        }
                      } else if (mode === 'selector') {
                        const imageKey = key;
                        const imageIdKey = `${itemId}_${imageKey}`;
                        setLoadingImages(prevState => ({ ...prevState, [imageIdKey]: true }));

                        toggleImageSelection(itemId, column.id, key, !isSelected)
                          .then(() => {
                            setLoadingImages(prevState => {
                              const newState = { ...prevState };
                              delete newState[imageIdKey];
                              return newState;
                            });
                          })
                          .catch(() => {
                            setLoadingImages(prevState => {
                              const newState = { ...prevState };
                              delete newState[imageIdKey];
                              return newState;
                            });
                          });
                      } else if (mode === 'transform') {
                        // Transform mode actions
                        openPopupTransform(url, itemId, column.id, key);
                        console.log('Transform mode action for image:', key);
                        // You can add functionality here later
                      }
                    }}
                  >
                    {mode === 'generate' ? (
                      label === 'Uncrop' ? 'Generate' :
                      label === 'Background' ? 'Generate' :
                      label === 'Import' ? 'Generate' :
                      'Generate'
                    ) : mode === 'selector' ? (
                      isSelected ? 'Deselect' : 'Select'
                    ) : mode === 'transform' ? (
                      'Transform'
                    ) : null}
                  </div>
                )}
                {mode === 'transform' && imageTypeLabel && url !== FALLBACK_IMAGE_URL && (
                  <div
                    className={styles.imageTypeLabel}
                    style={{ backgroundColor: getImageTypeColor(imageTypeLabel) }}
                  >
                    {imageTypeLabel}
                  </div>
                )}
              </div>
            );
          });

          return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start' }}>
              {images}
            </div>
          );
        }

        return value;
      },
    })) : [];

    return [selectColumn, ...otherColumns];
  }, [selectedColumns, clientData, mode, loadingImages, selectedRows]);

  const data = React.useMemo(() => clientData, [clientData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div>
      <div className={styles.topBar}>
        <button onClick={() => navigate(isAdmin ? '/admin' : '/client')} className={styles.backButton}>
          <FaArrowLeft size={20} />
        </button>


        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Quantum Feed Engine - Image Attributes</span>
          <span className={styles.headerSubtitle}>Get ready to boost your appearance</span>
        </div>

        <Select
          options={linkedClients}
          onChange={handleClientChange}
          styles={customStyles}
          className={styles.clientDropdown}
          placeholder="Select Feed"
        />

        {selectedClient && (
          <>
            <button onClick={handleRefresh} className={styles.refreshButton}>
              <FaSyncAlt size={20} />
            </button>

            <div className={styles.storageInfo}>
              <AiOutlineCloud size={20} />
              {storageLoading ? (
                <div className={styles.spinnerContainer}>
                  <ClipLoader size={12} color={"#d0d0d0"} />
                  <span>&nbsp;images,&nbsp;</span>
                  <ClipLoader size={12} color={"#d0d0d0"} />
                  <span>&nbsp;MB</span>
                </div>
              ) : (
                <span>{storageInfo.file_count} images, {storageInfo.total_size_mb.toFixed(2)} MB</span>
              )}
            </div>
          </>
        )}


        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>

      {availableColumns.length > 0 && (
        <div className={styles.columnSelectionContainer}>
          <div className={styles.columnList}>
            <Select
              options={availableColumns}
              isMulti
              value={selectedColumns}
              onChange={handleColumnSelection}
              styles={customStyles}
              className={styles.clientDropdown}
              placeholder="Available Columns"
              closeMenuOnSelect={false}
            />
          </div>
          <button onClick={handleStandardView} className={styles.backButton}>
            <FaColumns size={20} />
          </button>
        </div>
      )}

      {availableColumns.length > 0 && (
        <div className={styles.combinedFilterContainer}>
          {filters.map((filter, index) => (
            <div key={index} className={styles.filterRow}>
              {index > 0 && (
                <select
                  value={filter.logic}
                  onChange={(e) => handleFilterChange(index, 'logic', e.target.value)}
                  className={styles.logicSelect}
                >
                  <option value="AND">AND</option>
                  <option value="OR">OR</option>
                </select>
              )}

              <Select
                options={filterAvailableColumns}
                onChange={(selectedOption) => handleFilterChange(index, 'column', selectedOption.value)}
                styles={customStyles}
                className={styles.filterDropdown}
                placeholder="Select Column"
                value={filter.column ? { value: filter.column, label: filter.column } : null}
              />

              <select
                value={filter.type}
                onChange={(e) => handleFilterChange(index, 'type', e.target.value)}
                className={styles.filterTypeSelect}
              >
                <option value="equals">Equals</option>
                <option value="not_equals">Is Not Equal To</option>
                <option value="in">In (Use this for pasting multiple values - e.g. ids)</option> {/* New Option */}
                <option value="not_in">Not In</option> {/* New Option */}
                <option value="contains">Contains</option>
                <option value="does_not_contain">Does Not Contain</option>
                <option value="starts_with">Starts With</option>
                <option value="ends_with">Ends With</option>
                <option value="is_blank">Is Blank</option>
                <option value="is_not_blank">Is Not Blank</option>
                <option value="length_equals">Length Equals</option>
                <option value="length_greater_than">Length Greater Than</option>
                <option value="length_less_than">Length Less Than</option>
                <option value="is_greater_than">Is Greater Than</option>
                <option value="is_less_than">Is Less Than</option>
                <option value="matches_regexp">Matches Regexp</option>
                <option value="doesnt_match_regexp">Doesn't Match Regexp</option>
              </select>


              <input
                type="text"
                value={filter.value}
                onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                className={styles.filterInput}
                placeholder={
                  (filter.type === 'in' || filter.type === 'not_in')
                    ? "Enter values separated by comma or space"
                    : "Enter filter value"
                }
                disabled={filter.type === 'is_blank' || filter.type === 'is_not_blank'}
              />




              <button onClick={() => removeFilter(index)} className={styles.removeFilterButton}>
                X
              </button>
            </div>
          ))}

          <div className={styles.sortByContainer}>
            <div className={styles.sortByGroup}>
              <Select
                options={availableColumns}
                onChange={handleSortColumnChange}
                styles={{
                  ...customStyles,
                  control: (provided) => ({
                    ...provided,
                    width: '250px',
                  }),
                }}
                className={styles.sortColumnDropdown}
                placeholder="Sort by Column"
              />

              <select
                value={sortOrder}
                onChange={handleSortOrderChange}
                className={styles.sortOrderSelect}
                style={{ width: '120px', height: '38px' }}
              >
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
              </select>
            </div>

            <div className={styles.buttonGroup}>
              <button onClick={addFilter} className={styles.addFilterButton} style={{ width: '50px', height: '50px' }}>
                <FaPlus size={20} />
              </button>

              <button
                onClick={() => {
                  setCurrentPage(1);
                  applyFilters(1);
                }}
                className={styles.applyFilterButton}
                style={{ width: '50px', height: '50px' }}
                disabled={!areAllColumnsSelected()}
              >
                <FaPlay size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      <PopupUploaded
        isOpen={isPopupOpen}
        onClose={closePopup}
        imageUrl={selectedImage?.url}
        column={selectedImage?.column}
        id={selectedImage?.id}
        client={selectedImage?.client}
        onImageUploadSuccess={() => {
          applyFilters();
        }}
      />
      <PopupBackground
        isOpen={isNoBackgroundPopupOpen}
        onClose={() => setIsNoBackgroundPopupOpen(false)}
        imageUrl={noBackgroundImage?.url}
        column={noBackgroundImage?.column}
        id={noBackgroundImage?.id}
        client={noBackgroundImage?.client}
        onGenerationSuccess={() => applyFilters()}
      />

      <PopupUncropped
        isOpen={isPopupUncroppedOpen}
        onClose={closePopupUncropped}
        imageUrl={selectedUncroppedImage?.url}
        existingProcessedImageUrl={selectedUncroppedImage?.processedUrl}
        columnName={selectedUncroppedImage?.column}
        itemId={selectedUncroppedImage?.id}
        client={selectedUncroppedImage?.client}
        onUncropSuccess={() => {
          applyFilters();
        }}
      />

      <PopupImport
        isOpen={isPopupImportOpen}
        onClose={closePopupImport}
        imageUrl={selectedImportImage?.url}
        columnName={selectedImportImage?.columnName}
        id={selectedImportImage?.id}
        client={selectedImportImage?.client}
        onImportSuccess={() => {
          applyFilters();
        }}
      />

      <PopupTransform
        isOpen={isPopupTransformOpen}
        onClose={closePopupTransform}
        imageUrl={transformImage?.url}
        column={transformImage?.column}
        id={transformImage?.id}
        client={transformImage?.client}
        imageKey={transformImage?.imageKey} // Pass imageKey to PopupTransform
        onTransformSuccess={() => {
          applyFilters();
        }}
      />


      <PopupExport
        isOpen={isPopupExportOpen}
        onClose={closePopupExport}
        exportData={clientData} // Existing prop
        clientName={selectedClient} // New prop
      />


<PopupBulkActions
        isOpen={isPopupBulkActionsOpen}
        onClose={() => setIsPopupBulkActionsOpen(false)}
        selectedIds={selectedRows}
        filters={filters}
        selectedClient={selectedClient}
        refreshData={refreshData}
      />




      {selectedColumns.length > 0 && (
        <div className={styles.tableContainer}>
          <div className={styles.tableWrapper}>
            <div className={styles.buttonContainer}>
              <div className={styles.toggleSwitch}>
                <button
                  className={`${styles.toggleButton} ${mode === 'generate' ? styles.generateActiveButton : styles.generateMode}`}
                  onClick={() => setMode('generate')}
                >
                  Generate Mode
                </button>
                <button
                  className={`${styles.toggleButton} ${mode === 'selector' ? styles.selectorActiveButton : styles.selectorMode}`}
                  onClick={() => setMode('selector')}
                >
                  Selector Mode
                </button>
                <button
                  className={`${styles.toggleButton} ${mode === 'transform' ? styles.transformActiveButton : styles.transformMode}`}
                  onClick={() => setMode('transform')}
                >
                  Transform Mode
                </button>
              </div>

              <div className={styles.rightButtons}>
                <button
                  className={styles.bulkActionsButton}
                  onClick={() => setIsPopupBulkActionsOpen(true)}
                >
                  Bulk Actions
                </button>


                <button
                  className={styles.exportButton}
                  onClick={openPopupExport}
                  title="Export Data"
                >
                  <FaFileExport size={20} />
                </button>
              </div>
            </div>

            <table {...getTableProps()} className={styles.table}>
              <thead
                className={
                  mode === 'generate' ? styles.generateHeader :
                  mode === 'selector' ? styles.selectorHeader :
                  styles.transformHeader
                }>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
               <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={selectedRows.includes(row.original.id) ? styles.selectedRow : ''}
                    >
                      {row.cells.map(cell => (
                        <td
                          {...cell.getCellProps()}
                          onClick={cell.column.id === 'selection' ? (event) => {
                            if (event.target.type !== 'checkbox') {
                              handleRowSelect(row, event);
                            }
                          } : undefined}
                          style={{
                            cursor: cell.column.id === 'selection' ? 'pointer' : '',
                            userSelect: isShiftPressed ? 'none' : 'text',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {selectedColumns.length > 0 && (
        <div className={styles.paginationContainer}>
          <button
            className={styles.paginationButton}
            onClick={handlePreviousPage}
            disabled={isPageLoading || currentPage === 1}
          >
            {loadingButton === 'prev' ? <ClipLoader size={12} color={"#FFFFFF"} /> : <FaArrowLeft />}
          </button>

          <span>
            Page
            <input
              type="number"
              value={currentPage}
              onChange={(e) => setCurrentPage(e.target.value)}
              onBlur={() => jumpToPage()}
              min="1"
              max={maxPage}
              className={styles.pageInput}
              style={{ width: '40px', marginLeft: '5px', marginRight: '5px', textAlign: 'center' }}
            />
            out of {maxPage} ({totalItems} items)
          </span>

          <span>
            <select
              value={itemsPerPage}
              onChange={(e) => {
                const newPageSize = parseInt(e.target.value, 10);
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              className={styles.itemsPerPageDropdown}
              style={{ marginLeft: '5px' }}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </select>
          </span>

          <button
            className={styles.paginationButton}
            onClick={handleNextPage}
            disabled={isPageLoading || currentPage === maxPage}
          >
            {loadingButton === 'next' ? <ClipLoader size={12} color={"#FFFFFF"} /> : <FaArrowRight />}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGeneration;
