// src/components/popups/PopupBackground.js

import React, { useState, useEffect } from 'react';
import {
  MdImage,
  MdColorLens,
  MdVisibilityOff,
  MdAspectRatio,
  MdCrop,
  MdFitScreen,
  MdFullscreen,
  MdPersonOutline,
  MdPhotoSizeSelectActual,
  MdInfoOutline,
  MdBlurOn,
} from 'react-icons/md';
import { SketchPicker } from 'react-color';
import { ClipLoader } from 'react-spinners';
import styles from './css/PopupBackground.module.css';

// Access the environment variable
const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

const PopupBackground = ({
  isOpen,
  onClose,
  imageUrl,
  client,
  id,
  column,
  onGenerationSuccess,
}) => {
  const [selectedBackground, setSelectedBackground] = useState('transparent');
  const [color, setColor] = useState({
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  });
  const [openDropdown, setOpenDropdown] = useState(null);
  const [generatedImageUrl, setGeneratedImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const [isUploadingBackgroundImage, setIsUploadingBackgroundImage] =
    useState(false);

  // State variables for size options
  const [selectedSizeOption, setSelectedSizeOption] = useState('original');
  const [originalWidth, setOriginalWidth] = useState('');
  const [originalHeight, setOriginalHeight] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  // State variables for positioning options
  const [scalingMode, setScalingMode] = useState('fit'); // 'fit' or 'fill'
  const [paddingValue, setPaddingValue] = useState('');
  const [paddingUnit, setPaddingUnit] = useState('%'); // '%' or 'px'
  const [referenceBox, setReferenceBox] = useState('subject'); // 'subject' or 'original'

  // **New State for Shadow**
  const [shadow, setShadow] = useState('None'); // 'None', 'Soft', 'Hard', 'Floating'

  // State variables for validation
  const [paddingError, setPaddingError] = useState('');

  const [aiPrompt, setAiPrompt] = useState('');
  const [aiPromptError, setAiPromptError] = useState('');

  // Additional State variables for Effects
  const [relightImage, setRelightImage] = useState(false);
  const [removeText, setRemoveText] = useState(false);
  const [removeTextMode, setRemoveTextMode] = useState('all');

  const handleBackgroundChange = (value) => {
    setSelectedBackground(value);
    setBackgroundImageUrl(null); // Reset if background type changes

    // Reset AI prompt if not AI Generated
    if (value !== 'ai_generated') {
      setAiPrompt('');
      setAiPromptError('');
    }
  };

  const handleColorChange = (newColor) => {
    setColor({
      hex: newColor.hex,
      rgb: newColor.rgb,
    });
  };

  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploadingBackgroundImage(true);
      // Create FormData
      const formData = new FormData();
      formData.append('file', file);
      formData.append('client', client);
      formData.append('id', id);
      formData.append('column', column);

      try {
        const response = await fetch(
          `${API_URL_IMAGE}/upload-background-image`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to upload background image');
        }

        const result = await response.json();
        setBackgroundImageUrl(result.publicUrl);
      } catch (error) {
        console.error('Error uploading background image:', error);
        alert('Failed to upload background image. Please try again.');
      } finally {
        setIsUploadingBackgroundImage(false);
      }
    }
  };

  const getOutputDimensions = () => {
    let outputWidth = 0;
    let outputHeight = 0;
    if (selectedSizeOption === 'original') {
      outputWidth = originalWidth;
      outputHeight = originalHeight;
    } else if (selectedSizeOption === 'custom') {
      outputWidth = parseInt(width, 10);
      outputHeight = parseInt(height, 10);
    }
    return { outputWidth, outputHeight };
  };

  const handlePaddingValueChange = (value) => {
    setPaddingValue(value);

    // Validation
    let error = '';
    if (value === '') {
      // No error if value is empty
      setPaddingError('');
      return;
    }

    if (paddingUnit === '%') {
      if (value < 1 || value > 49) {
        error = 'Value must be between 1 and 49%';
      }
    } else if (paddingUnit === 'px') {
      const { outputWidth, outputHeight } = getOutputDimensions();
      const minDimension = Math.min(outputWidth, outputHeight);
      if (minDimension) {
        const maxPx = Math.floor(minDimension / 2) - 1;
        if (value < 1 || value > maxPx) {
          error = `Value must be between 1 and ${maxPx}px`;
        }
      } else {
        error = 'Output dimensions not set';
      }
    }
    setPaddingError(error);
  };

  const handlePaddingUnitChange = (unit) => {
    setPaddingUnit(unit);
    setPaddingValue(''); // Reset the padding value
    setPaddingError(''); // Reset the error
  };

  const submitData = async () => {
    if (isUploadingBackgroundImage) {
      alert('Background image is still uploading. Please wait.');
      return;
    }

    if (paddingError) {
      alert('Please correct the padding value.');
      return;
    }

    // Validate AI Prompt if AI Generated is selected
    if (selectedBackground === 'ai_generated') {
      if (aiPrompt.trim() === '') {
        setAiPromptError('AI prompt cannot be empty.');
        alert('Please enter an AI prompt.');
        return;
      }
      if (aiPrompt.length > 200) {
        setAiPromptError('AI prompt cannot exceed 200 characters.');
        alert('AI prompt is too long.');
        return;
      }
    }

    setIsLoading(true);
    try {
      const data = {
        removeBackground: selectedBackground !== 'original',
        background: selectedBackground,
        color:
          selectedBackground === 'color'
            ? color.hex.replace('#', '')
            : '00000000',
        imageUrl: imageUrl,
        client: client,
        id: id,
        column: column,
        scaling: scalingMode === 'fill' ? 'fill' : 'fit',
        paddingValue: paddingValue || '0',
        paddingUnit: paddingUnit,
        referenceBox: referenceBox,
        // Include Shadow Parameter
        shadow: shadow,
        // Include Effects Parameters
        relightImage: relightImage,
        removeText: removeText,
        removeTextMode: removeText ? removeTextMode : undefined,
      };

      // Include backgroundImageUrl only if background is 'image'
      if (selectedBackground === 'image' && backgroundImageUrl) {
        data.backgroundImageUrl = backgroundImageUrl;
      }

      // Include AI Prompt if background is 'AI Generated'
      if (selectedBackground === 'ai_generated' && aiPrompt.trim() !== '') {
        data.backgroundPrompt = aiPrompt.trim();
      }

      // Include custom size if 'Custom Size' is selected
      if (selectedSizeOption === 'custom' && width && height) {
        data.width = width;
        data.height = height;
      }

      const response = await fetch(
        `${API_URL_IMAGE}/background-removal`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to apply settings');
      }

      const result = await response.json();
      console.log('Background settings applied successfully:', result);

      setGeneratedImageUrl(result.publicUrl);

      // Call the onGenerationSuccess prop if it exists
      if (onGenerationSuccess) {
        onGenerationSuccess();
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setGeneratedImageUrl(null);
      setBackgroundImageUrl(null);
      setSelectedSizeOption('original');
      setWidth('');
      setHeight('');
      setScalingMode('fit');
      setPaddingValue('');
      setPaddingUnit('%');
      setReferenceBox('subject');
      setPaddingError('');
      setShadow('None'); // **Reset shadow state**

      // Load the original image to get its dimensions
      const img = new Image();
      img.onload = function () {
        setOriginalWidth(this.naturalWidth);
        setOriginalHeight(this.naturalHeight);
        setWidth(this.naturalWidth);
        setHeight(this.naturalHeight);
      };
      img.src = imageUrl;
    }
  }, [isOpen, imageUrl]);

  useEffect(() => {
    setGeneratedImageUrl(null);
  }, [imageUrl]);

  const toggleDropdown = (section) => {
    setOpenDropdown((prevSection) =>
      prevSection === section ? null : section
    );
  };

  if (!isOpen) return null;

  const handleAiPromptChange = (value) => {
    setAiPrompt(value);

    // Validation
    if (selectedBackground === 'ai_generated') {
      if (value.trim() === '') {
        setAiPromptError('AI prompt cannot be empty.');
      } else if (value.length > 200) {
        setAiPromptError('AI prompt cannot exceed 200 characters.');
      } else {
        setAiPromptError('');
      }
    } else {
      setAiPromptError('');
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Function to determine the content of the color indicator
  const getColorIndicatorStyle = () => {
    switch (selectedBackground) {
      case 'color':
        return {
          backgroundColor: color.hex,
        };
      case 'transparent':
        // The checkerboard pattern is already handled by the className
        return {};
      case 'original':
        return {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      case 'image':
        return {
          backgroundImage: `url(${backgroundImageUrl || ''})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      case 'ai_generated':
        return {
          backgroundImage: 'url(https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/AI_icon.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      default:
        return {};
    }
  };

  const handleSizeOptionChange = (value) => {
    setSelectedSizeOption(value);
    if (value === 'original') {
      setWidth(originalWidth);
      setHeight(originalHeight);
    } else {
      setWidth('');
      setHeight('');
    }
    // Re-validate padding value in case dimensions have changed
    if (paddingUnit === 'px' && paddingValue !== '') {
      handlePaddingValueChange(paddingValue);
    }
  };

  const handleWidthChange = (value) => {
    setWidth(value);
    // Re-validate padding value when dimensions change
    if (paddingUnit === 'px' && paddingValue !== '') {
      handlePaddingValueChange(paddingValue);
    }
  };

  const handleHeightChange = (value) => {
    setHeight(value);
    // Re-validate padding value when dimensions change
    if (paddingUnit === 'px' && paddingValue !== '') {
      handlePaddingValueChange(paddingValue);
    }
  };

  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div
        className={styles.popupContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.popupHeader}>
          <h2>Background Image Configuration</h2>
        </div>

        <div className={styles.configurationSection}>
          <div className={styles.dropdownRow}>
            {/* Background Section */}
            <div className={styles.dropdownSection}>
              <button
                className={styles.dropdownHeader}
                onClick={() => toggleDropdown('background')}
              >
                <div className={styles.dropdownHeaderContent}>
                  <span>Background</span>
                  <div
                    className={`${styles.colorIndicator} ${
                      selectedBackground === 'transparent'
                        ? styles.checkerboardBackground
                        : ''
                    }`}
                    style={getColorIndicatorStyle()}
                  ></div>
                </div>
              </button>
              {openDropdown === 'background' && (
                <div className={styles.dropdownContent}>
                  {/* Background Type Header */}
                  <div className={styles.subSectionTitle}>
                    Background Type
                    <div className={styles.tooltipContainer}>
                      <MdInfoOutline className={styles.infoIcon} />
                      <span className={styles.tooltipText}>
                        Choose the background option for your image:
                        <br />
                        <strong>Original:</strong> Keep the original background.
                        <br />
                        <strong>Transparent:</strong> Remove the background and make it transparent.
                        <br />
                        <strong>Color:</strong> Set a solid color as the background.
                        <br />
                        <strong>Image:</strong> Upload a custom background image.
                        <br />
                        <strong>AI Generated:</strong> Generate a background using AI based on a prompt.
                      </span>
                    </div>
                  </div>

                  {/* Background Options with Tooltips */}
                  <div
                    className={`${styles.option} ${
                      selectedBackground === 'original' ? styles.active : ''
                    }`}
                    onClick={() => handleBackgroundChange('original')}
                  >
                    <MdImage size={24} />
                    <span>Original</span>
                  </div>
                  <div
                    className={`${styles.option} ${
                      selectedBackground === 'transparent' ? styles.active : ''
                    }`}
                    onClick={() => handleBackgroundChange('transparent')}
                  >
                    <MdVisibilityOff size={24} />
                    <span>Transparent</span>
                  </div>
                  <div
                    className={`${styles.option} ${
                      selectedBackground === 'color' ? styles.active : ''
                    }`}
                    onClick={() => handleBackgroundChange('color')}
                  >
                    <MdColorLens size={24} />
                    <span>Color</span>
                  </div>
                  <div
                    className={`${styles.option} ${
                      selectedBackground === 'image' ? styles.active : ''
                    }`}
                    onClick={() => handleBackgroundChange('image')}
                  >
                    <MdImage size={24} />
                    <span>Image</span>
                  </div>
                  <div
                    className={`${styles.option} ${
                      selectedBackground === 'ai_generated' ? styles.active : ''
                    }`}
                    onClick={() => handleBackgroundChange('ai_generated')}
                  >
                    <MdBlurOn size={24} />
                    <span>AI Generated</span>
                  </div>

                  {/* Color Picker for the "Color" option */}
                  {selectedBackground === 'color' && (
                    <div className={styles.colorPicker}>
                      <SketchPicker
                        color={color.rgb}
                        onChangeComplete={handleColorChange}
                        onChange={handleColorChange}
                        width="100%"
                      />
                    </div>
                  )}

                  {/* File Input for the "Image" option */}
                  {selectedBackground === 'image' && (
                    <div className={styles.imageUpload}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleBackgroundImageUpload}
                      />
                      {isUploadingBackgroundImage && (
                        <div className={styles.loaderContainer}>
                          <ClipLoader color="#000000" size={20} />
                        </div>
                      )}
                      {backgroundImageUrl && (
                        <div className={styles.backgroundImagePreview}>
                          <img
                            src={backgroundImageUrl}
                            alt="Background Preview"
                            className={styles.previewImage}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {selectedBackground === 'ai_generated' && (
                    <div className={styles.aiPromptContainer}>
                      <input
                        type="text"
                        value={aiPrompt}
                        onChange={(e) => handleAiPromptChange(e.target.value)}
                        className={styles.aiPromptInput}
                        placeholder="Enter AI prompt..."
                      />
                      {aiPromptError && (
                        <div className={styles.errorMessage}>{aiPromptError}</div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Positioning Section */}
            <div className={styles.dropdownSection}>
              <button
                className={styles.dropdownHeader}
                onClick={() => toggleDropdown('positioning')}
              >
                Positioning
              </button>
              {openDropdown === 'positioning' && (
                <div className={styles.dropdownContent}>
                  {/* Scaling Mode */}
                  <div className={styles.subSection}>
                    <div className={styles.subSectionTitle}>
                      Scaling Mode
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          <strong>Fit:</strong> Ensures the entire subject is visible without cropping.
                          <br />
                          <strong>Fill:</strong> Fills the entire area, which may crop parts of the subject.
                        </span>
                      </div>
                    </div>
                    <div className={styles.radioGroup}>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="scalingMode"
                          value="fit"
                          checked={scalingMode === 'fit'}
                          onChange={() => setScalingMode('fit')}
                        />
                        <span className={styles.customRadio}></span>
                        <MdFitScreen size={20} />
                        <span>Fit</span>
                      </label>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="scalingMode"
                          value="fill"
                          checked={scalingMode === 'fill'}
                          onChange={() => setScalingMode('fill')}
                        />
                        <span className={styles.customRadio}></span>
                        <MdFullscreen size={20} />
                        <span>Fill</span>
                      </label>
                    </div>
                  </div>

                  {/* Padding */}
                  <div className={styles.subSection}>
                    <div className={styles.subSectionTitle}>
                      Padding
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          <strong>Padding:</strong> Adds space around the subject.
                          <br />
                          Use percentage (%) for relative padding or pixels (px) for fixed padding.
                        </span>
                      </div>
                    </div>
                    <div className={styles.paddingInputContainer}>
                      <input
                        type="number"
                        value={paddingValue}
                        onChange={(e) =>
                          handlePaddingValueChange(e.target.value)
                        }
                        className={styles.paddingInput}
                        placeholder="0"
                      />
                      <select
                        value={paddingUnit}
                        onChange={(e) =>
                          handlePaddingUnitChange(e.target.value)
                        }
                        className={styles.paddingUnitSelect}
                      >
                        <option value="%">%</option>
                        <option value="px">px</option>
                      </select>
                    </div>
                    {paddingError && (
                      <div className={styles.errorMessage}>{paddingError}</div>
                    )}
                  </div>

                  {/* Reference Box */}
                  <div className={styles.subSection}>
                    <div className={styles.subSectionTitle}>
                      Reference Box
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          <strong>Subject:</strong> Use the subject of the image as the reference for positioning.
                          <br />
                          <strong>Original:</strong> Use the original image dimensions as the reference.
                        </span>
                      </div>
                    </div>
                    <div className={styles.radioGroup}>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="referenceBox"
                          value="subject"
                          checked={referenceBox === 'subject'}
                          onChange={() => setReferenceBox('subject')}
                        />
                        <span className={styles.customRadio}></span>
                        <MdPersonOutline size={20} />
                        <span>Subject</span>
                      </label>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="referenceBox"
                          value="original"
                          checked={referenceBox === 'original'}
                          onChange={() => setReferenceBox('original')}
                        />
                        <span className={styles.customRadio}></span>
                        <MdPhotoSizeSelectActual size={20} />
                        <span>Original</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Shadow Section */}
            <div className={styles.dropdownSection}>
              <button
                className={styles.dropdownHeader}
                onClick={() => toggleDropdown('shadow')}
              >
                <div className={styles.dropdownHeaderContent}>
                  <span>Shadow</span>
                </div>
              </button>
              {openDropdown === 'shadow' && (
                <div className={styles.dropdownContent}>
                  {/* Shadow Options */}
                  <div className={styles.subSection}>
                    <div className={styles.subSectionTitle}>
                      Shadow Type
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          <strong>None:</strong> No shadow is applied.
                          <br />
                          <strong>Soft:</strong> A soft shadow is applied to give a subtle effect.
                          <br />
                          <strong>Hard:</strong> A sharp shadow for more contrast.
                          <br />
                          <strong>Floating:</strong> A floating effect with shadow underneath the subject.
                        </span>
                      </div>
                    </div>

                    {/* Shadow Options in Vertical Layout */}
                    <div className={`${styles.radioGroup} ${styles.verticalRadioGroup}`}>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="shadow"
                          value="None"
                          checked={shadow === 'None'}
                          onChange={() => setShadow('None')}
                        />
                        <span className={styles.customRadio}></span>
                        <span>None</span>
                      </label>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="shadow"
                          value="Soft"
                          checked={shadow === 'Soft'}
                          onChange={() => setShadow('Soft')}
                        />
                        <span className={styles.customRadio}></span>
                        <span>Soft</span>
                      </label>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="shadow"
                          value="Hard"
                          checked={shadow === 'Hard'}
                          onChange={() => setShadow('Hard')}
                        />
                        <span className={styles.customRadio}></span>
                        <span>Hard</span>
                      </label>
                      <label className={styles.customRadioOption}>
                        <input
                          type="radio"
                          name="shadow"
                          value="Floating"
                          checked={shadow === 'Floating'}
                          onChange={() => setShadow('Floating')}
                        />
                        <span className={styles.customRadio}></span>
                        <span>Floating</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Effects Section */}
            <div className={styles.dropdownSection}>
              <button
                className={styles.dropdownHeader}
                onClick={() => toggleDropdown('effects')}
              >
                <div className={styles.dropdownHeaderContent}>
                  <span>Effects</span>
                </div>
              </button>
              {openDropdown === 'effects' && (
                <div className={styles.dropdownContent}>
                  {/* Relight Image Option */}
                  <div className={styles.effectOption}>
                    <label className={styles.switchLabel}>
                      <span>Relight Image</span>
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          Automatically adjust the lighting of the image for better clarity and aesthetics.
                        </span>
                      </div>
                    </label>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={relightImage}
                        onChange={() => setRelightImage(!relightImage)}
                      />
                      <span className={styles.slider}></span>
                    </label>
                  </div>
                  {/* Remove Text Option */}
                  <div className={styles.effectOption}>
                    <label className={styles.switchLabel}>
                      <span>Remove Text</span>
                      <div className={styles.tooltipContainer}>
                        <MdInfoOutline className={styles.infoIcon} />
                        <span className={styles.tooltipText}>
                          Remove embedded text from the image. Choose the removal intensity based on your needs.
                          <br /><br />
                          <strong>All:</strong> All text (natural and artificial) will be automatically removed.
                          <br /><br />
                          <strong>Natural:</strong> Natural text includes text that naturally occurs in an image such as writing on buildings or clothing, road signs, etc.
                          <br /><br />
                          <strong>Artificial:</strong> Artificial text includes all text added on an image through post-processing, such as company name, watermarks, discounts, etc.
                        </span>
                      </div>
                    </label>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={removeText}
                        onChange={() => setRemoveText(!removeText)}
                      />
                      <span className={styles.slider}></span>
                    </label>
                  </div>

                  {/* Remove Text Modes */}
                  {removeText && (
                    <div className={styles.removeTextModes}>
                      <div className={styles.radioGroup}>
                        <label className={styles.customRadioOption}>
                          <input
                            type="radio"
                            name="removeTextMode"
                            value="all"
                            checked={removeTextMode === 'all'}
                            onChange={() => setRemoveTextMode('all')}
                          />
                          <span className={styles.customRadio}></span>
                          <span>All</span>
                        </label>
                        <label className={styles.customRadioOption}>
                          <input
                            type="radio"
                            name="removeTextMode"
                            value="natural"
                            checked={removeTextMode === 'natural'}
                            onChange={() => setRemoveTextMode('natural')}
                          />
                          <span className={styles.customRadio}></span>
                          <span>Natural</span>
                        </label>
                        <label className={styles.customRadioOption}>
                          <input
                            type="radio"
                            name="removeTextMode"
                            value="artificial"
                            checked={removeTextMode === 'artificial'}
                            onChange={() => setRemoveTextMode('artificial')}
                          />
                          <span className={styles.customRadio}></span>
                          <span>Artificial</span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Size Section */}
            <div className={styles.dropdownSection}>
              <button
                className={styles.dropdownHeader}
                onClick={() => toggleDropdown('size')}
              >
                <div className={styles.dropdownHeaderContent}>
                  <span>Size</span>
                </div>
              </button>
              {openDropdown === 'size' && (
                <div className={styles.dropdownContent}>
                  {/* Size Options */}
                  <div
                    className={`${styles.option} ${
                      selectedSizeOption === 'original' ? styles.active : ''
                    }`}
                    onClick={() => handleSizeOptionChange('original')}
                  >
                    <MdAspectRatio size={24} />
                    <span>Original Size</span>
                  </div>
                  <div
                    className={`${styles.option} ${
                      selectedSizeOption === 'custom' ? styles.active : ''
                    }`}
                    onClick={() => handleSizeOptionChange('custom')}
                  >
                    <MdCrop size={24} />
                    <span>Custom Size</span>
                  </div>

                  {/* Input fields for both options */}
                  <div className={styles.sizeInputContainer}>
                    <input
                      type="number"
                      value={width}
                      onChange={(e) => handleWidthChange(e.target.value)}
                      className={styles.sizeInput}
                      placeholder="Width"
                      disabled={selectedSizeOption === 'original'}
                    />
                    <span>x</span>
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => handleHeightChange(e.target.value)}
                      className={styles.sizeInput}
                      placeholder="Height"
                      disabled={selectedSizeOption === 'original'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Images Side by Side */}
        <div className={styles.imagesContainer}>
          {/* Before Image */}
          <div className={styles.sideBySideImageContainer}>
            <div className={styles.imageLabel}>Original Image</div>
            <div className={styles.imageWrapper}>
              <img
                src={
                  imageUrl ||
                  'https://storage.googleapis.com/quantum-feed-engine/workbench/error_image.png'
                }
                alt="Before"
                className={styles.sideBySideImage}
              />
            </div>
          </div>

          {/* After Image */}
          <div className={styles.sideBySideImageContainer}>
            <div className={styles.imageLabel}>Processed Image</div>
            <div className={styles.imageWrapper}>
              {isLoading && (
                <div className={styles.loadingOverlay}>
                  <ClipLoader color="#ffffff" size={50} />
                </div>
              )}
              <img
                src={
                  generatedImageUrl ||
                  'https://storage.googleapis.com/quantum-feed-engine/workbench/error_image.png'
                }
                alt="After"
                className={styles.sideBySideImage}
              />
            </div>
          </div>
        </div>

        {/* Button Container */}
        <div className={styles.buttonContainer}>
          <button
            onClick={submitData}
            className={styles.submitButton}
            disabled={
              isLoading ||
              isUploadingBackgroundImage ||
              (selectedBackground === 'image' && !backgroundImageUrl) ||
              (selectedSizeOption === 'custom' && (!width || !height)) ||
              (selectedBackground === 'ai_generated' && aiPrompt.trim() === '')
              // No additional disabling needed for Effects as modes are always selected when enabled
            }
          >
            {isLoading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              'Generate Image'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupBackground;
