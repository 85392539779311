// src/popups/PopupBulkActionPages/UncropperPage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import styles from './UncropperPage.module.css';

const UncropperPage = ({
  allIds,
  filterIds,
  selectedIds,
  selectedClient,
  filters,
  refreshData,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
}) => {
  // Implement your UncropperPage logic using the passed props

  return (
    <div className={styles.pageContainer}>
      <h3>Uncropper Page</h3>
      <p>Selected Client: {selectedClient}</p>
      <p>Total IDs: {allIds.length}</p>
      <p>Filtered IDs: {filterIds.length}</p>
      <p>Selected IDs: {selectedIds.length}</p>
    </div>
  );
};

// Define PropTypes for type checking
UncropperPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  selectedClient: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  refreshData: PropTypes.func.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
};

export default React.memo(UncropperPage);
