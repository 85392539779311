// src/popups/PopupBulkActionPages/TransformerPage.jsx

import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styles from './TransformerPage.module.css';
import ClipLoader from "react-spinners/ClipLoader";

const TransformerPage = ({
  allIds,
  filterIds,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
  selectedIds = [],
  filters = [],
  selectedClient,
  refreshData,
}) => {
  // Initialize state with values from localStorage or default values
  const [imageColumns, setImageColumns] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState(() => {
    try {
      const saved = localStorage.getItem('selectedColumns');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing selectedColumns from localStorage:', e);
      return [];
    }
  });
  const [imageTypes, setImageTypes] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = React.useState(() => {
    try {
      const saved = localStorage.getItem('selectedTypes');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing selectedTypes from localStorage:', e);
      return [];
    }
  });
  const [selectionScope, setSelectionScope] = React.useState(() => {
    const saved = localStorage.getItem('selectionScope');
    return saved ? saved : null;
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [transformFormat, setTransformFormat] = React.useState(() => {
    const saved = localStorage.getItem('transformFormat');
    return saved ? saved : null;
  });
  const [successCount, setSuccessCount] = React.useState(0);
  const [failureDetails, setFailureDetails] = React.useState(() => {
    try {
      const saved = localStorage.getItem('failureDetails');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing failureDetails from localStorage:', e);
      return [];
    }
  });

  // Debugging: Log state changes
  React.useEffect(() => {
    console.log('Selection Scope:', selectionScope);
  }, [selectionScope]);

  React.useEffect(() => {
    console.log('Selected Columns:', selectedColumns);
  }, [selectedColumns]);

  React.useEffect(() => {
    console.log('Selected Types:', selectedTypes);
  }, [selectedTypes]);

  React.useEffect(() => {
    console.log('Transform Format:', transformFormat);
  }, [transformFormat]);

  // Dynamically generate image columns including 'image_link'
  React.useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };

    setImageColumns(generateImageColumns());
  }, []);

  // Define all image types
  React.useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  // Persist selectedColumns to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
    } catch (e) {
      console.error('Error saving selectedColumns to localStorage:', e);
    }
  }, [selectedColumns]);

  // Persist selectedTypes to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('selectedTypes', JSON.stringify(selectedTypes));
    } catch (e) {
      console.error('Error saving selectedTypes to localStorage:', e);
    }
  }, [selectedTypes]);

  // Persist selectionScope to localStorage whenever it changes
  React.useEffect(() => {
    if (selectionScope) {
      localStorage.setItem('selectionScope', selectionScope);
    } else {
      localStorage.removeItem('selectionScope');
    }
  }, [selectionScope]);

  // Persist transformFormat to localStorage whenever it changes
  React.useEffect(() => {
    if (transformFormat) {
      localStorage.setItem('transformFormat', transformFormat);
    } else {
      localStorage.removeItem('transformFormat');
    }
  }, [transformFormat]);

  // Persist failureDetails to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('failureDetails', JSON.stringify(failureDetails));
    } catch (e) {
      console.error('Error saving failureDetails to localStorage:', e);
    }
  }, [failureDetails]);

  // Handle changes in image column selection
  const handleColumnChange = (selectedOptions) => {
    const columns = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedColumns(columns);
  };

  // Handle changes in image type selection
  const handleTypeChange = (type) => {
    setSelectedTypes((prev) =>
      prev.includes(type)
        ? prev.filter((t) => t !== type)
        : [...prev, type]
    );
  };

  // Handle changes in selection scope
  const handleScopeChange = (scope) => {
    setSelectionScope(scope);
  };

  // Handle transform format change
  const handleTransformFormatChange = (selectedOption) => {
    setTransformFormat(selectedOption ? selectedOption.value : null);
  };

  // Handle bulk transform action
  const handleTransform = async () => {
    console.log('Transform action initiated');
    
    if (!selectionScope) {
      setFailureDetails([{ error: 'Please select a scope before proceeding.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    if (!transformFormat) {
      setFailureDetails([{ error: 'Please select a transformation format.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    let idsToTransform = [];
    if (selectionScope === 'selectAll') {
      idsToTransform = allIds;
    } else if (selectionScope === 'selectCurrentView') {
      idsToTransform = filterIds;
    } else if (selectionScope === 'selectSelectedItems') {
      idsToTransform = selectedIds;
    }

    if (idsToTransform.length === 0) {
      setFailureDetails([{ error: 'No items found for the selected scope.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    if (selectedColumns.length === 0 || selectedTypes.length === 0) {
      setFailureDetails([{ error: 'Please select at least one image column and one image type.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    const requestData = {
      client_name: selectedClient,
      ids: idsToTransform,
      image_columns: selectedColumns,
      image_types: selectedTypes,
      transform_format: transformFormat, // 'png', 'webp', or 'jpg'
    };

    setIsLoading(true);
    setSuccessCount(0);
    setFailureDetails([]); // Clear previous failure details before new transformation

    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL_IMAGE;

      if (!API_BASE_URL) {
        throw new Error('REACT_APP_API_URL_IMAGE is not defined in the environment variables.');
      }

      const response = await fetch(`${API_BASE_URL}/transform-images-bulk`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        setSuccessCount(responseData.success || 0);
        setFailureDetails(responseData.failures || []);
        if (refreshData) refreshData(); // Invoke the refreshData function to update the table
      } else {
        setFailureDetails([{ error: responseData.error || 'Unknown error occurred during transformation.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      }
    } catch (error) {
      setFailureDetails([{ error: 'An error occurred during the bulk image transformation.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      console.error('Bulk transformation error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Options for Select components
  const imageColumnOptions = imageColumns.map(column => ({ value: column, label: column }));
  const formatOptions = [
    { value: 'png', label: 'PNG' },
    { value: 'webp', label: 'WEBP' },
    { value: 'jpg', label: 'JPG' },
  ];

  return (
    <div className={styles.pageContainer}>
      {/* Header Section: Description and Image Side by Side */}
      <div className={styles.headerSection}>
        {/* Description */}
        <div className={styles.description}>
          <h2>Transform Images in Bulk</h2>
          <p>
            This tab allows you to transform image formats in bulk. 
            Please choose the relevant image columns, types, transformation format, and scope of items that should be processed.
          </p>
        </div>

        {/* Header Image */}
        <div className={styles.headerImage}>
          <img 
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/img-transform-removebg-preview.png" 
            alt="Image Transformation Overview" 
          />
        </div>
      </div>

      {/* Select Image Columns */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Image Columns</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the image columns you want to transform.
            </span>
          </div>
        </div>
        <Select
          isMulti
          options={imageColumnOptions}
          className={styles.multiSelect}
          classNamePrefix="select"
          onChange={handleColumnChange}
          value={imageColumnOptions.filter(option => selectedColumns.includes(option.value))}
          placeholder="Select image columns..."
          closeMenuOnSelect={false}
        />
      </div>

      {/* Select Image Types */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Image Types</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the types of images you want to transform.
            </span>
          </div>
        </div>
        <div className={styles.checkboxGroup}>
          {imageTypes.map((type) => (
            <label key={type} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(type)}
                onChange={() => handleTypeChange(type)}
              />
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </label>
          ))}
        </div>
      </div>

      {/* Select Scope */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Scope</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the scope of items you want to apply the transformation to.
            </span>
          </div>
        </div>
        <div className={styles.scopeGroup}>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectSelectedItems' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectSelectedItems')}
          >
            Selected IDs ({selectedIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectCurrentView' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectCurrentView')}
          >
            Filtered IDs ({filterIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectAll' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectAll')}
          >
            All IDs ({allIds.length})
          </button>
        </div>
      </div>

      {/* Transformation Format and Action Button */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Transformation Format</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the image format you want to transform the selected images to.
            </span>
          </div>
        </div>
        <div className={styles.formatAndButton}>
          <Select
            options={formatOptions}
            className={styles.actionSelect}
            classNamePrefix="select"
            onChange={handleTransformFormatChange}
            value={formatOptions.find(option => option.value === transformFormat) || null}
            placeholder="PNG/JPG/WEBP"
            isClearable
          />
          <button
            type="button"
            className={styles.applyButton}
            onClick={handleTransform}
            disabled={
              isLoading ||
              !selectionScope ||
              selectedColumns.length === 0 ||
              selectedTypes.length === 0 ||
              !transformFormat
            }
          >
            {isLoading ? 'Transforming...' : 'Transform Images'}
          </button>
        </div>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <ClipLoader size={50} color={"#EB7B47"} loading={true} />
          <p>Transforming images...</p>
        </div>
      )}

      {/* Transformation Status */}
      {!isLoading && (successCount > 0 || failureDetails.length > 0) && (
        <div className={styles.resultSummary}>
          <h4>Transformation Summary</h4>
          {successCount > 0 && <p>Successfully transformed images: {successCount}</p>}
          {failureDetails.length > 0 && (
            <>
              <p>Failed transformations: {failureDetails.length}</p>
              <div className={styles.failureTable}>
                <h4>Failed Transformations</h4>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Column</th>
                      <th>Image Type</th>
                      <th>Image Preview</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failureDetails.map((failure, index) => (
                      <tr key={index}>
                        <td>{failure.id || 'N/A'}</td>
                        <td>{failure.column || 'N/A'}</td>
                        <td>{failure.image_type || 'N/A'}</td>
                        <td>
                          {failure.image_url && failure.image_url !== 'N/A' ? (
                            <div className={styles.imageContainer}>
                              <img 
                                src={failure.image_url} 
                                alt="Image preview"
                                onError={(e) => { e.target.onerror = null; e.target.src="path/to/placeholder.png"; }} // Optional: handle image load errors
                              />
                            </div>
                          ) : 'N/A'}
                        </td>
                        <td>{failure.error || 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

// Define PropTypes for type checking
TransformerPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
  selectedIds: PropTypes.array,
  filters: PropTypes.array,
  selectedClient: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

// Removed React.memo for debugging purposes
export default TransformerPage;
