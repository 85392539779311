// src/popups/PopupBulkActionPages/ImporterPage.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ImporterPage.module.css';
import Select from 'react-select';
import ClipLoader from "react-spinners/ClipLoader"; // For loading indicators

// Define the base API URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_URL_IMAGE;

const ImporterPage = ({
  allIds,
  filterIds,
  selectedIds,
  selectedClient,
  filters,
  refreshData,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
}) => {
  const [importType, setImportType] = useState('internal'); // 'internal' or 'file'
  const [fromField, setFromField] = useState(null);
  const [toField, setToField] = useState(null);
  const [fromImageType, setFromImageType] = useState(null);
  const [idSelection, setIdSelection] = useState(null); // 'all', 'filtered', 'selected'
  const [isApplying, setIsApplying] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [imageColumns, setImageColumns] = useState([]);
  const [imageTypes, setImageTypes] = useState([]);

  // Dynamically generate image columns including 'image_link'
  useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };

    setImageColumns(generateImageColumns());
  }, []);

  // Define all image types
  useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  // Fetch available fields (Assuming 'filters' prop contains field information)
  useEffect(() => {
    if (filters && Array.isArray(filters)) {
      // Extract field names from filters
      const fields = filters.map(filter => filter.fieldName); // Adjust based on your filters structure
      setAvailableFields(fields);
    }
  }, [filters]);

  // Handler for toggling import type
  const handleToggle = (type) => {
    setImportType(type);
    // Reset fields when switching import types
    setFromField(null);
    setToField(null);
    setFromImageType(null);
    setIdSelection(null);
  };

  // Handler for Apply button
  const handleApply = async () => {
    if (!fromField || !toField || !fromImageType || !idSelection) {
      alert('Please select From field, To field, Image Type, and ID selection.');
      return;
    }

    let idsToProcess = [];
    if (idSelection === 'all') {
      idsToProcess = allIds;
    } else if (idSelection === 'filtered') {
      idsToProcess = filterIds;
    } else if (idSelection === 'selected') {
      idsToProcess = selectedIds;
    }

    if (idsToProcess.length === 0) {
      alert('No IDs available for the selected scope.');
      return;
    }

    const requestData = {
      client_name: selectedClient,
      ids: idsToProcess,
      from_field: fromField.value,
      to_field: toField.value,
      from_image_type: fromImageType,
      to_image_type: 'import', // Set to 'import' by default
      import_type: importType,
    };

    setIsApplying(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/internal-import`, { // Updated endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Internal import successful.');
        if (refreshData) refreshData();
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Internal import error:', error);
      alert('An error occurred during the internal import.');
    } finally {
      setIsApplying(false);
    }
  };

  // Options for Select components
  const fieldOptions = imageColumns.map(column => ({ value: column, label: column }));
  const idSelectionOptions = [
    { value: 'selected', label: `Selected IDs (${selectedIds.length})` },
    { value: 'filtered', label: `Filtered IDs (${filterIds.length})` },
    { value: 'all', label: `All IDs (${allIds.length})` },
  ];

  // **New: Compute whether the Apply button should be disabled**
  const isApplyDisabled = isApplying || !fromField || !toField || !fromImageType || !idSelection;

  return (
    <div className={styles.pageContainer}>
      <h3>Importer Page</h3>
      <p>Selected Client: {selectedClient}</p>

      {/* Toggle Switch */}
      <div className={styles.toggleSwitch}>
        <button
          className={`${styles.toggleButton} ${
            importType === 'internal' ? styles.internalActiveButton : styles.internalMode
          }`}
          onClick={() => handleToggle('internal')}
        >
          Internal Import
        </button>
        <button
          className={`${styles.toggleButton} ${
            importType === 'file' ? styles.fileActiveButton : styles.fileMode
          }`}
          onClick={() => handleToggle('file')}
        >
          Based on File
        </button>
      </div>

      {/* Internal Import View */}
      {importType === 'internal' && (
        <div className={styles.internalImportContainer}>
          {/* From Field Group */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>From:</label>
            <div className={styles.fieldRow}>
              <div className={styles.selectContainer}>
                <Select
                  options={fieldOptions}
                  value={fromField}
                  onChange={setFromField}
                  placeholder="Select source field..."
                  className={styles.select}
                  classNamePrefix="select"
                />
              </div>
              <div className={styles.imageTypeContainer}>
                <span className={styles.imageTypeLabel}>Image Type:</span>
                <div className={styles.radioGroup}>
                  {imageTypes.map((type) => (
                    <label key={`from-${type}`} className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="fromImageType"
                        value={type}
                        checked={fromImageType === type}
                        onChange={() => setFromImageType(type)}
                      />
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* To Field Group */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>To:</label>
            <div className={styles.fieldRow}>
              <div className={styles.selectContainer}>
                <Select
                  options={fieldOptions}
                  value={toField}
                  onChange={setToField}
                  placeholder="Select target field..."
                  className={styles.select}
                  classNamePrefix="select"
                />
              </div>
              <div className={styles.imageTypeContainer}>
                <span className={styles.imageTypeLabel}>Image Type:</span>
                <span className={styles.importPlaceholder}>
                  This will be placed in the "import" placeholder.
                </span>
              </div>
            </div>
          </div>

          {/* ID Selection */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>For / ID Selection:</label>
            <div className={styles.idSelectionContainer}>
              <div className={styles.scopeGroup}>
                {idSelectionOptions.map(option => (
                  <button
                    key={option.value}
                    type="button"
                    className={`${styles.scopeButton} ${idSelection === option.value ? styles.activeScopeButton : ''}`}
                    onClick={() => setIdSelection(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Apply Button */}
          <div className={styles.buttonGroup}>
            <button
              className={styles.applyButton}
              onClick={handleApply}
              disabled={isApplyDisabled} // **Updated: Disable button based on isApplyDisabled**
            >
              {isApplying ? 'Applying...' : 'Apply'}
            </button>
          </div>

          {/* Loading Indicator */}
          {isApplying && (
            <div className={styles.loadingOverlay}>
              <ClipLoader size={50} color={"#123abc"} loading={true} />
              <p>Applying internal import...</p>
            </div>
          )}
        </div>
      )}

      {/* Based on File View */}
      {importType === 'file' && (
        <div className={styles.fileImportContainer}>
          {/* Placeholder for Based on File functionality */}
          <h4>Based on File Import</h4>
          <p>Select a file to import data:</p>
          <input type="file" accept=".csv, .xlsx" className={styles.fileInput} />
          <button className={styles.applyButton}>Upload</button>
          {/* Implement the upload and processing logic as needed */}
        </div>
      )}
    </div>
  );
};

// Define PropTypes for type checking
ImporterPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  selectedClient: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired, // Assuming filters contain field information
  refreshData: PropTypes.func.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
};

export default React.memo(ImporterPage);
