// src/popups/PopupBulkActions.jsx

import React, { useState, useEffect, useRef } from 'react';
import styles from './css/PopupBulkActions.module.css';
import { FaTimes, FaCrop, FaPaintBrush, FaUpload, FaArrowsAltH, FaMousePointer } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";

// Importing page components
import UncropperPage from './PopupBulkActionPages/UncropperPage';
import BackgroundManipulatorPage from './PopupBulkActionPages/BackgroundManipulatorPage';
import ImporterPage from './PopupBulkActionPages/ImporterPage';
import TransformerPage from './PopupBulkActionPages/TransformerPage';
import SelectorPage from './PopupBulkActionPages/SelectorPage';

// Access the environment variable
const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

const PopupBulkActions = ({ isOpen, onClose, selectedIds, filters, selectedClient, refreshData }) => {
  const [activeTab, setActiveTab] = useState('uncropper');
  const [allIds, setAllIds] = useState([]);
  const [filterIds, setFilterIds] = useState([]);
  const [isAllIdsLoading, setIsAllIdsLoading] = useState(false);
  const [isFilterIdsLoading, setIsFilterIdsLoading] = useState(false);
  const [allIdsError, setAllIdsError] = useState(null);
  const [filterIdsError, setFilterIdsError] = useState(null);
  const popupRef = useRef(null);

  // Centralized data fetching
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the popup when "Esc" is pressed
      }
    };

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if the click is outside the popup container
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (selectedClient) {
      fetchAllIds();
      fetchFilterIds();
    } else {
      setAllIds([]);
      setFilterIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, filters]);

  // Function to fetch all IDs without filters
  const fetchAllIds = async () => {
    setIsAllIdsLoading(true);
    setAllIdsError(null);
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/get-ids-by-filters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          client_name: selectedClient,
          filters: [], // No filters to get all IDs
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setAllIds(data.ids);
        console.log('All IDs:', data.ids);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch all IDs:', errorData.error);
        setAllIdsError(errorData.error || 'Failed to fetch all IDs.');
      }
    } catch (error) {
      console.error('Error fetching all IDs:', error);
      setAllIdsError('Error fetching all IDs.');
    } finally {
      setIsAllIdsLoading(false);
    }
  };

  // Function to fetch IDs based on current filters
  const fetchFilterIds = async () => {
    setIsFilterIdsLoading(true);
    setFilterIdsError(null);
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/get-ids-by-filters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          client_name: selectedClient,
          filters: filters, // Current filters
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setFilterIds(data.ids);
        console.log('Filter IDs:', data.ids);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch IDs by filters:', errorData.error);
        setFilterIdsError(errorData.error || 'Failed to fetch filtered IDs.');
      }
    } catch (error) {
      console.error('Error fetching IDs by filters:', error);
      setFilterIdsError('Error fetching filtered IDs.');
    } finally {
      setIsFilterIdsLoading(false);
    }
  };

  // Render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'uncropper':
        return (
          <UncropperPage
            allIds={allIds}
            filterIds={filterIds}
            selectedIds={selectedIds}
            selectedClient={selectedClient}
            filters={filters}
            refreshData={refreshData}
            isAllIdsLoading={isAllIdsLoading}
            isFilterIdsLoading={isFilterIdsLoading}
            allIdsError={allIdsError}
            filterIdsError={filterIdsError}
          />
        );
      case 'backgroundManipulator':
        return (
          <BackgroundManipulatorPage
            allIds={allIds}
            filterIds={filterIds}
            selectedIds={selectedIds}
            selectedClient={selectedClient}
            filters={filters}
            refreshData={refreshData}
            isAllIdsLoading={isAllIdsLoading}
            isFilterIdsLoading={isFilterIdsLoading}
            allIdsError={allIdsError}
            filterIdsError={filterIdsError}
          />
        );
      case 'importer':
        return (
          <ImporterPage
            allIds={allIds}
            filterIds={filterIds}
            selectedIds={selectedIds}
            selectedClient={selectedClient}
            filters={filters}
            refreshData={refreshData}
            isAllIdsLoading={isAllIdsLoading}
            isFilterIdsLoading={isFilterIdsLoading}
            allIdsError={allIdsError}
            filterIdsError={filterIdsError}
          />
        );
      case 'transformer':
        return (
          <TransformerPage
            allIds={allIds}
            filterIds={filterIds}
            selectedIds={selectedIds}
            selectedClient={selectedClient}
            filters={filters}
            refreshData={refreshData}
            isAllIdsLoading={isAllIdsLoading}
            isFilterIdsLoading={isFilterIdsLoading}
            allIdsError={allIdsError}
            filterIdsError={filterIdsError}
          />
        );
      case 'selector':
        return (
          <SelectorPage
            allIds={allIds}
            filterIds={filterIds}
            selectedIds={selectedIds}
            selectedClient={selectedClient}
            filters={filters}
            refreshData={refreshData}
            isAllIdsLoading={isAllIdsLoading}
            isFilterIdsLoading={isFilterIdsLoading}
            allIdsError={allIdsError}
            filterIdsError={filterIdsError}
          />
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup} ref={popupRef}>
        <div className={styles.headerAndTabs}>
          <div className={styles.headerLeft}>
            <div className={styles.headerTitle}>
              <h2>Bulk Actions</h2>
            </div>
            <div className={styles.tabContainer}>
              {/* Image Editing Group */}
              <div className={styles.tabGroup}>
                <div className={styles.groupLabel}>EDITING</div>
                <div className={styles.tabRow}>
                  <button
                    className={`${styles.tabButton} ${activeTab === 'uncropper' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('uncropper')}
                  >
                    <FaCrop className={styles.tabIcon} /> Uncropper
                  </button>
                  <button
                    className={`${styles.tabButton} ${activeTab === 'backgroundManipulator' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('backgroundManipulator')}
                  >
                    <FaPaintBrush className={styles.tabIcon} /> Background Manipulator
                  </button>
                  <button
                    className={`${styles.tabButton} ${activeTab === 'importer' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('importer')}
                  >
                    <FaUpload className={styles.tabIcon} /> Importer
                  </button>
                </div>
              </div>

              {/* Divider */}
              <div className={styles.divider}></div>

              {/* Image Transforming Group */}
              <div className={styles.tabGroup}>
                <div className={styles.groupLabel}>TRANSFORMING</div>
                <div className={styles.tabRow}>
                  <button
                    className={`${styles.tabButton} ${activeTab === 'transformer' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('transformer')}
                  >
                    <FaArrowsAltH className={styles.tabIcon} /> Transformer
                  </button>
                </div>
              </div>

              {/* Divider */}
              <div className={styles.divider}></div>

              {/* Image Selection Group */}
              <div className={styles.tabGroup}>
                <div className={styles.groupLabel}>SELECTION</div>
                <div className={styles.tabRow}>
                  <button
                    className={`${styles.tabButton} ${activeTab === 'selector' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('selector')}
                  >
                    <FaMousePointer className={styles.tabIcon} /> Selector
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.headerRight}>
            <button className={styles.closeButton} onClick={onClose}>
              <FaTimes />
            </button>
          </div>
        </div>
        <div className={styles.contentArea}>
          {/* Display loading indicators or error messages if necessary */}
          {(isAllIdsLoading || isFilterIdsLoading) && (
            <div className={styles.loadingOverlay}>
              <ClipLoader size={50} color={"#123abc"} loading={true} />
              <p>Loading data...</p>
            </div>
          )}
          {(allIdsError || filterIdsError) && (
            <div className={styles.errorOverlay}>
              <p>Error: {allIdsError || filterIdsError}</p>
            </div>
          )}
          {!isAllIdsLoading && !isFilterIdsLoading && !allIdsError && !filterIdsError && renderContent()}
        </div>
      </div>
    </div>
  );
};

// Define PropTypes for type checking
PopupBulkActions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedIds: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  selectedClient: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default PopupBulkActions;
